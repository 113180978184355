.bar{  
  padding: 5px;
}
.nav-1 b{
  color: red;
}
.qtdClientes{
  margin-left: 50px;
}
.user-icon{
  margin-right: 15px;
}
.icon-hora{
  color: green;
}
.qtdClientes{
  margin-left: 100px;
}
.navbar {
  background-color: #ffffff; 
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
}
.navbar-brand img {
  width: 60px;
  height: 70px;
}
.navbar-toggler-icon {
  border: 2px solid #28a745; 
}
.navbar-nav {
  align-items: center; 
  display: flex;
  gap: 5px;
}
.nav-link {
  color: #333; 
  font-weight: bold; 
  margin-right: 10px; 
  
}
.nav-link:hover {
  color: #28a745; 
}
.icon-hora,
.fa-dollar-sign,
.fa-google,
.fa-tag,
.fa-right-from-bracket {
  margin-right: 5px; 
}
.exibicao {
  align-items: center; 
}
.qtdClientes,
.qtdClientesAss {
  font-weight: bold; 
  margin-right: 20px; 
}
.bar {
  color: #ccc; 
  font-weight: bold; 
}
.text-danger {
  color: #dc3545; 
}
.btn-nav-ct{
    color: #464E80;
    font-size: small;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.4);

}
.btn-nav-ct0{
  color: #ffffff;
  font-size: small;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.4);

}