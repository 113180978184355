
.linha-branca {
	height: 150px;
	width: 100%;
	margin-top: 100px;
	background-color: #fff;
	position: absolute;
	z-index: -1;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.sobre {
	position: relative;
	border: 5px double #6F7BBF;
	margin-top: -30px;
	border-radius: 25px;
	height: auto;
	background: linear-gradient(120deg, #374DDC, #374DDC, #A240B6);
	transition: all .5s ease-in-out;
}

.sobre::before,
.sobre::after,
.sobre::before,
.sobre::after {
	content: '';
	position: absolute;
	width: 70px;
	/* Adjust as needed */
	height: 70px;
	/* Adjust as needed */
	background: #fff;
	/* Match the gradient */
}

.sobre::before {
	top: -25px;
	/* Adjust to match the border-radius */
	left: -25px;
	/* Adjust to match the border-radius */
	border-bottom-right-radius: 50%;
	rotate: 25deg;
}

.sobre::after {
	bottom: -25px;
	/* Adjust to match the border-radius */
	right: -25px;
	/* Adjust to match the border-radius */
	rotate: 25deg;
	border-top-left-radius: 50%;
}

.sobre:hover {
	scale: 1.01;
}

.titulo-caixa {
	margin-top: 50px;
}

.dados-sobre {
	display: flex;
	align-items: center;
}

.img-sobre {
	position: relative;
}

.img-sobre1 {
	margin-top: 50px;
	border-radius: 25px;
}

.text-sobre {
	flex: 1;
}