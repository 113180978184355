nav .navbar-toggler {
	background-color: #374DDC;
}

.nav .nav-link {
	color: #fff;
}

.nav.ativo {
	background-color: #fff;
	opacity: 0.95;
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
	transition: background-color 0.3s linear;
	color: #000;
}

.navbar-brand img {
	margin-top: 10px;
	width: 100px;
	height: 80px;
}

.btn-nav{
	display: flex;
	gap: 10px;
}

@media screen and (max-width: 992px) {

	nav {
		background-color: #fff;
	}

	nav .nav-link {
		color: #000 !important;
	}

	nav .btn {
		color: #000!important;
	}

	.banner {
		margin-top: 100px;
		height: auto;
	}

	.box-serv p {
		margin-bottom: 30px !important;
	}
}



nav .btn{
	color: #fff;
}